import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(`../views/${view}.vue`);
}

Vue.router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  // hashbang: false,
  // mode: 'history',
  // base: __dirname,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        auth: true
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: loadView('dashboard/Dashboard'),
        },
        // Rapport
        {
          name: 'Rapport',
          path: 'pages/rapport',
          component: loadView('dashboard/pages/Rapport'),
        },

        // Statistique
        {
          name: 'Statistique',
          path: 'pages/statistique',
          component: loadView('dashboard/pages/Statistique'),
        },

        // Administration
        {
          name: 'Administration',
          path: 'pages/administration',
          component: loadView('dashboard/pages/Administration'),
        },

        // Setting
        {
          name: 'Parametre',
          path: 'pages/parametre',
          component: loadView('dashboard/pages/Setting'),
        },

        // Message
        {
          name: 'Communication',
          path: 'pages/communication',
          component: loadView('dashboard/pages/Communication'),
        },

        // Client
        {
          name: 'Crm',
          path: 'pages/crm',
          component: loadView('dashboard/pages/Crm'),
        },

        // Compte
        {
          name: 'Mon compte',
          path: 'pages/profile',
          component: loadView('dashboard/pages/Setting/ProfileSetting'),
        },

        // defaut template route
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: loadView('dashboard/component/Notifications'),
        },
        {
          name: 'Button',
          path: 'components/buttons',
          component: loadView('dashboard/component/Buttons'),
        },
        
        {
          name: 'Icons',
          path: 'components/icons',
          component: loadView('dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: loadView('dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: loadView('dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: loadView('dashboard/maps/GoogleMaps'),

        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: loadView('dashboard/Upgrade'),
        },
      ],
    },
    // Login
    {
      name: 'login',
      path: '/login',
      component: loadView('Login'),
      meta: {
        auth: false
      }
    },
  ],
});

export default Vue.router;
