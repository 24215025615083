import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import axios from 'axios'


// const instance = axios.create({
//   baseURL: 'http://localhost:8080/xpress-api/public/api'
// });

Vue.use(Vuex)


const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({

  modules: {
    auth
  },

  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
    userInfos: {
      code_postal : '',
      created_at : '',
      email : '',
      email_verified_at : '',
      etp : '',
      first_con : null,
      function : '',
      id : null,
      name : '',
      phone : '',
      pressing_id : null,
      profile : '',
      quartier : '',
      rue : '',
      service : '',
      sexe : '',
      surname : '',
      type_id : null,
      updated_at : '',
      username : '',
      ville : '',
    },
  },

  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },

    userInfos(state, userInfos) {
      state.userInfos = userInfos;
    },
  },

  actions: {

    getUserInfos: ({commit}) => {


      axios.get('/auth/user')

        .then(function (response) {
          commit('userInfos', response.data.user);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('menu', JSON.stringify(response.data.menu));
          localStorage.setItem('site', JSON.stringify(response.data.site));
        })
        .catch(function (state) {
          
          commit('userInfos', state.userInfos);
          // localStorage.removeItem('user');
        });

    },

  },

  strict: debug
})
