import Vue from 'vue';


import http from './http';
import store from './store';
import router from './router';
import config from './config';

import App from './App.vue';

import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

// Element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr';

// keen ui
import KeenUI from 'keen-ui';
import 'keen-ui/dist/keen-ui.css';
// Nprogress
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// Apexchart
import VueApexCharts from 'vue-apexcharts';

// Bootstrap
import BootstrapVue from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";

//vue-step-wizard
import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'

// stepProgress global
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Vue easy-table
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import { VeLocale } from "vue-easytable";
import { VeIcon } from "vue-easytable";
import enUS from "vue-easytable/libs/locale/lang/en-US.js"; // import language config

import axios from 'axios';
// axios.defaults.baseURL = 'http://localhost:8080/xpress-api/public/api/';

// Import sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#007FFF',
  cancelButtonColor: '#DE1C1C',
};


// import vue tree
import VueTree from '@ssthouse/vue-tree-chart';



library.add(faUserSecret);

Vue.config.productionTip = false;

Vue.use(ElementUI, {locale});
Vue.use(KeenUI);
Vue.use(VueApexCharts);
Vue.use(VueSweetalert2, options);
Vue.use(NProgress);
Vue.use(BootstrapVue);
Vue.use(StepProgress);
Vue.use(VueStepWizard);

Vue.use(VeIcon);
VeLocale.use(enUS);
Vue.use(VueEasytable);
// Vue.use(axios);
// Vue.use(moment);\
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-tree', VueTree);

new Vue({
  http,  
  store,
  router,
  config,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
