import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueApexCharts from 'vue-apexcharts'

Vue.use(Vuetify)
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)

const theme = {
  primary: '#007FFF',
  secondary: '#56A5F0',
  accent: '#001D6E',
  info: '#00CAE3',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
