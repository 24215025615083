import Vue from 'vue';

// Vue Resource
// import VueResource from 'vue-resource';

// Vue.use(VueResource);
// Vue.http.options.root = process.env.VUE_APP_API_URL;

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// axios.defaults.baseURL = 'http://localhost:8181/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
// axios.defaults.baseURL = 'https://d.xjoel.com/api';
axios.defaults.baseURL = 'https://xperf-api.xjoel.com/api/';

let token = sessionStorage.getItem('auth_token_default');

if (token) {
  console.log(token);
  axios.defaults.headers.common['Authorization'] = 'Bearer' +token;
}

Vue.use(VueAxios, axios);

export default {
    root: process.env.VUE_APP_API_URL
};